<template>
    <div class="k-flex-col text-center">
        <h1 class="text-danger">404</h1>
        <p>Cette page n'existe pas ou n'existe plus</p>
        <div>
            <b-button class="m-2" variant="kalkin-1" @click="goHome">
                <b-icon-house-door-fill /> Retour à l'accueil</b-button
            >
        </div>
    </div>
</template>
<script>
export default {
    name: "NotFound",

    methods: {
        goHome: function() {
            this.$router.push({ name: "Home" });
        },
    },

    created: async function() {
        if (!this.$store.state.user.status.loggedIn) {
            this.$router.push({ name: "Login" });
        }
    },
};
</script>
